import * as React from 'react'
import { Autoplay, EffectFade } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import PropTypes from 'prop-types'

import Image from '../partials/image'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/autoplay'
import 'swiper/css/effect-fade'

// Image Slideshow with Adjustable Speed
const Slideshow = ({ slides, speed }) => {
	return (
		<div>
			<Swiper
				modules={[Autoplay, EffectFade]}
				autoplay={{ delay: speed, disableOnInteraction: false }} // Adjust Speed and Prevent Autoplay Pause
				effect="fade"
				speed={0}
				loop={true}
				slidesPerView={1}
			>
				{slides.map(slide => {
					return (
						<SwiperSlide key={`flip_slide__${slide.id}`}>
							<Image asset={slide} />
						</SwiperSlide>
					)
				})}
			</Swiper>
		</div>
	)
}

Slideshow.propTypes = {
	slides: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
		}).isRequired
	).isRequired,
	speed: PropTypes.number.isRequired,
}

export default Slideshow
